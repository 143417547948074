import * as React from "react";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import styles from "./index.module.css";

import Hero from "../../../components/en/ApartmentHero";
import Icon from "../../../components/Icon";
import Footer from "../../../components/Footer";

import photo1 from "../../../../img/apartments/classic_double/1.jpg";
import photo2 from "../../../../img/apartments/classic_double/2.jpg";
import photo3 from "../../../../img/apartments/classic_double/3.jpg";
import photo4 from "../../../../img/apartments/classic_double/4.jpg";
import photo5 from "../../../../img/apartments/classic_double/5.jpg";
import photo6 from "../../../../img/apartments/classic_double/6.jpg";
import photo7 from "../../../../img/apartments/classic_double/7.jpg";
import photo8 from "../../../../img/apartments/classic_double/8.jpg";
import photo9 from "../../../../img/apartments/classic_double/9.jpg";

export const DoubleRoom = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Classic Double/Twin Room</title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cinzel:wght@700&family=Coda&family=IBM+Plex+Serif:wght@300&display=swap" />
        <script data-react-helmet="true" src="https://kit.fontawesome.com/7488f5bdb2.js" crossorigin="anonymous"></script>
      </Helmet>
      <Hero title="Classic Double/Twin Room" photo={photo1} />
      <div className={styles.container}>
        <p className={styles.description}>Komfortowy pokój dwuosobowy o powierzchni 21 m2 z możliwością aranżacji łóżek: 1 podwójnym typu queen size (180 cm) lub 2 pojedynczymi (90 cm) z materacami typu premium, zapewniającym komfort wypoczynku. Stylowo wykończony i wyposażony. Klimatyzowany. Do dyspozycji naszych Gości w apartamencie, oprócz wody mineralnej, znajduje się ekspres do kawy z szerokim wyborem kaw w kapsułkach.</p>
        <div className={styles.grid}>
          <Icon apartment icon="fa-bed" text="1 łóżko queen size (180 cm)/2 pojedyncze (90 cm)" />
          <Icon apartment icon="fa-chair" text="Biurko z krzesłem" />
          <Icon apartment icon="fa-coffee" text="Czajnik, herbaty, ekspres Nespresso" />
          <Icon apartment icon="fa-lock" text="Sejf mieszczący laptopa 15.6" />
          <Icon apartment icon="fa-tint" text="Gazowana i niegazowana wodam" />
          <Icon apartment icon="fa-tv" text="Telewizor z dostępem do kanałów satelitarnych" />
          <Icon apartment icon="fa-wifi" text="Darmowe WI-FI" />
          <Icon apartment icon="fa-shower" text="2 komplety ręczników, żel, mydło, suszarka do włosów" />
        </div>
        <Carousel showArrows={true}>
                <div>
                  <img src={photo1} />
                </div>
                <div>
                  <img src={photo2} />
                </div>
                <div>
                  <img src={photo3} />
                </div>
                <div>
                  <img src={photo4} />
                </div>
                <div>
                  <img src={photo5} />
                </div>
                <div>
                  <img src={photo6} />
                </div>
                <div>
                  <img src={photo7} />
                </div>
                <div>
                  <img src={photo8} />
                </div>
        </Carousel>
        <hr />
        <p className={styles.regulations}>Sprzątanie pokoi odbywa się na życzenie Gości – uprzejmie prosimy o poinformowanie naszej recepcji lub wywieszenie zawieszki „Proszę posprzątać pokój” – najpóźniej do godz. 13:00. Goście mogą skorzystać z usług pralni (pranie na mokro za dodatkową opłata) oraz wypożyczyć żelazko wraz z deską do prasowania.</p>
        <hr />
        <p className={styles.regulations}>Wszystkie pokoje wraz z łazienkami i balkonami objęte są strefą dla niepalących. Dla bezpieczeństwa naszych Gości zostały one w czujniki dymu. Jedynym wyznaczonym miejscem do palenia jest patio znajdujące się na parterze vis a vis recepcji.</p>
        <hr />
      </div>
      <Footer />
    </>
  );
};

export default DoubleRoom;
